<template>
  <v-dialog width="950px" v-model="dialog" persistent>
    <v-card>
      <v-container class="pa-5">
        <v-row>
          <v-col cols="12">
            <!--Cancel-->
            <v-btn text class="float-right" @click="$emit('closeDialog')"
              ><v-icon small left>mdi-close</v-icon>Cancel</v-btn
            >
            <!--Save-->
            <v-btn
              class="float-right"
              color="primary"
              @click="saveCategories()"
              :disabled="selectedCategories.length === 0"
              :loading="loadingBtn"
              >Add Categories To Website ({{
                selectedCategories.length
              }})</v-btn
            >
            <h2>Categories</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-alert type="info" text v-if="!errorAlert">
              Select a product category from the system to display it on your
              ecommerce website
            </v-alert>
            <!--Error message-->
            <v-alert type="error" text v-else>
              Oops! There was an error... Please try again <br />
              {{ errorMessage }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :items="categories"
              :headers="headers"
              :search="search"
              dense
              v-model="selectedCategories"
              :items-per-page="100"
              item-key="category_id"
              show-select
              :loading="loadingTable"
              loading-text="Loading categories..."
            >
              <!--Search-->
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  placeholder="Search..."
                  append-icon="mdi-magnify"
                  dense
                  outlined
                  clearable
                ></v-text-field>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <!--Cancel-->
            <v-btn text class="float-right" @click="$emit('closeDialog')"
              ><v-icon small left>mdi-close</v-icon>Cancel</v-btn
            >
            <!--Save-->
            <v-btn
              class="float-right"
              color="primary"
              @click="saveCategories()"
              :disabled="selectedCategories.length === 0"
              :loading="loadingBtn"
              >Add Categories To Website ({{
                selectedCategories.length
              }})</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import db from "../../../../components/firebaseInit";
import store from "../../store";
import { mapActions } from "vuex";
import { showSnackbar } from "../../../../globalActions";
export default {
  name: "CategoryModal",
  props: ["dialog"],
  data() {
    return {
      loadingTable: false,
      loadingBtn: false,
      errorAlert: false,
      errorMessage: null,
      search: "",
      categories: [],
      selectedCategories: [],
      headers: [
        { text: "Name", value: "category_name", align: "start" },
        { text: "URL", value: "path" },
      ],
    };
  },
  store,
  methods: {
    ...mapActions(["setCategories"]),
    //  Creates the URL slug used to identify the category
    returnPath(name) {
      const regex = /[^a-zA-Z0-9$ ]/g;
      return `/${name
        .replace(".", "$")
        .replace(regex, "")
        .split(" ")
        .map((el) => el.toLowerCase())
        .join("-")
        .replace("--", "-")}`;
    },
    //  Get categories from Database
    async getCategories() {
      if (this.categories.length !== 0) return;
      this.loadingTable = true;
      const docRef = db
        .collection("categories")
        .where("category_status", "==", "Available");
      const snapshot = await docRef.get();
      // Filters and maps doc.data() to new array
      this.categories = snapshot.docs
        .reduce((acc, doc) => {
          if (!doc.data().ecommerce) {
            acc.push({
              ...doc.data(),
              path: this.returnPath(doc.data().category_name),
            });
          }
          return acc;
        }, [])
        .sort((a, b) => (a.category_name > b.category_name ? 1 : -1));
      this.loadingTable = false;
    },
    //
    async saveCategories() {
      this.errorAlert = false;
      this.loadingBtn = true;
      const batch = db.batch();
      this.selectedCategories.forEach((item) => {
        //  1) Update "ecommerce" status on category
        const categoryRef = db.collection("categories").doc(item.category_id);
        batch.update(categoryRef, {
          ecommerce: true,
        });
        //  2) Add selection to "ecommerce_categories" collection
        const ecomRef = db
          .collection("ecommerce_categories")
          .doc(item.category_id);
        batch.set(ecomRef, {
          id: ecomRef.id,
          name: item.category_name,
          description: null,
          src: null,
          path: item.path,
        });
      });
      try {
        await batch.commit();
        showSnackbar("Categories added to ecommerce website");
        this.$emit("categorySubmitted");
        this.$emit("closeDialog");
        // Mutate Vuex store with new categories
        this.setCategories();
        this.selectedCategories = [];
        this.categories = [];
        this.loadingBtn = false;
      } catch (error) {
        this.errorAlert = true;
        this.errorMessage = error;
        this.loadingBtn = false;
      }
    },
  },
};
</script>